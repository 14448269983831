import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import 'react-datetime/css/react-datetime.css';
import { Col, Card, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import Util from '../../../util/Util';
import moment from 'moment';
import ProfileImages from "../../../enum/ProfileImages";
import WorkWeek from '../../calendar/WorkWeek';
import { removeAppointmentModal, editAppointment, getAppointmentsByUser } from "../../../actions/appointment/appointment";
import { getsExceptionalOpenByDoctors } from "../../../actions/exceptionalOpen/exceptionalOpen";
import { getsExceptionalCloseByDoctors } from "../../../actions/exceptionalClose/exceptionalClose";
import SModalNewAppointment from './SModalNewAppointment';
import SModalTakeAppointment from './SModalTakeAppointment';
import SModalEditAppointment from './SModalEditAppointment';
import SModalExceptionalOpen from './SModalExceptionalOpen';
import SModalExceptionalClose from './SModalExceptionalClose';
import SModalWarningExceptionalClose from './SModalWarningExceptionalClose';
import SModalWarningAppointment from './SModalWarningAppointment';
import { allBySubWithDate } from '../../../actions/agreement/agreement';
import AppointmentUtil from '../../../util/AppointmentUtil';
import Noty from 'noty';
import "../../../../node_modules/noty/lib/noty.css";
import "../../../../node_modules/noty/lib/themes/bootstrap-v4.css";

const DnDCalendar = withDragAndDrop(Calendar)
const localizer = momentLocalizer(moment);

class SCalendarWeeks extends Component {

    constructor(props) {
        super(props);

        this.state = {
            events: [],
            arrayAgreements: [],
            appointments: [],
            currentDate: new Date(),
            startDate: moment().startOf('week'),
            endDate: moment().endOf('week').subtract(1, 'day'),
            min: new Date(0, 0, 0, 7, 0, 0),
            max: new Date(0, 0, 0, 20, 0, 0),
            collapseHours: true,
            collapseMorning: true,
            collapseAfternoon: true,
            userSelect: {
                id: '',
                name: '',
                first_name: '',
                image: ''
            },
            arrayDoc: [],
            showViewAppointment: false,
            arrayOfficeHours: [],
            modalNewAppointment: false,
            modalTakeAppointment: false,
            dateNewAppointmentSelected: '',
            modalEditAppointment: false,
            appointment: '',
            modalWarning: false,
            warning: '',
            event: {},
            modalExceptionalOpen: false,
            modalExceptionalClose: false,
            calendarEvents: [],
            availabilitys: [],
            modalWarningExceptionalClose: false,

        };

        this.onNavigate = this.onNavigate.bind(this);
        this.collapseHours = this.collapseHours.bind(this);
        this.customToolBar = this.customToolBar.bind(this);
        this.dayEventCustom = this.dayEventCustom.bind(this);
        this.officeHours = this.officeHours.bind(this);
        this.openNewAppointment = this.openNewAppointment.bind(this);
        this.closeModalNewAppointment = this.closeModalNewAppointment.bind(this);
        this.openModalTakeAppointment = this.openModalTakeAppointment.bind(this);
        this.closeModalTakeAppointment = this.closeModalTakeAppointment.bind(this);
        this.showViewAppointment = this.showViewAppointment.bind(this);
        this.loadDoc = this.loadDoc.bind(this);
        this.closeModalEditAppointment = this.closeModalEditAppointment.bind(this);
        this.editModal = this.editModal.bind(this);
        this.handleDragEvent = this.handleDragEvent.bind(this);
        this.closeModalWarning = this.closeModalWarning.bind(this);
        this.timeSlotWrapper = this.timeSlotWrapper.bind(this);
        this.showViewExceptionalOpen = this.showViewExceptionalOpen.bind(this);
        this.showViewExceptionalClose = this.showViewExceptionalClose.bind(this);
        this.closeModalExceptionalOpen = this.closeModalExceptionalOpen.bind(this);
        this.closeModalWarningExceptionalClose = this.closeModalWarningExceptionalClose.bind(this);
    };

    componentDidMount() {
        this.props.allBySubWithDate(moment(this.state.startDate).format('YYYY-MM-DD'), moment(this.state.endDate).format('YYYY-MM-DD'));

        if (this.props.user) {
            let data = {
                usersArray: [this.props.user._id],
                startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
                endDate: moment(this.state.endDate).format('YYYY-MM-DD')
            }

            this.props.getAppointmentsByUser(data);

            this.setState({
                userSelect: {
                    id: this.props.user._id,
                    name: this.props.user.name,
                    first_name: this.props.user.first_name,
                    image: this.props.user.image
                }
            })
        }

        if (this.props.agreements && this.props.agreements.length > 0) {
            this.officeHours(this.state.currentDate);

            const arrayAgreements = this.formatAgreements(this.props.agreements);

            this.setState({
                arrayAgreements: arrayAgreements,
            })
        }

        if (this.props.appointmentsByUsers && this.props.appointmentsByUsers.length > 0) {
            const appointmentsEvents = AppointmentUtil.formatAppointments(this.props.appointmentsByUsers, this.props.user._id);

            this.setState({
                appointments: appointmentsEvents,
            })
        }

        if (this.props.calendarEvents) {
            const calendarEvents = (this.props.calendarEvents && this.props.calendarEvents.length) ? AppointmentUtil.formatCalendarEvents(this.props.calendarEvents) : [];

            this.setState({
                calendarEvents: calendarEvents,
            })
        }

        if (this.props.availabilitys && this.props.agreements) {
            const availabilitys = (this.props.availabilitys && this.props.availabilitys.length) ? this.formatAvailabilitys(this.props.availabilitys, this.props.agreements) : [];

            this.setState({
                availabilitys: availabilitys,
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            let data = {
                usersArray: [this.props.user._id],
                startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
                endDate: moment(this.state.endDate).format('YYYY-MM-DD')
            }

            this.props.getAppointmentsByUser(data);

            this.setState({
                userSelect: {
                    id: this.props.user._id,
                    name: this.props.user.name,
                    first_name: this.props.user.first_name,
                    image: this.props.user.image
                }
            })
        }

        if (prevProps.agreements !== this.props.agreements) {
            let arrayDoctorId = [];
            this.props.agreements.forEach((elem) => {
                arrayDoctorId.push(elem.doctor_id);
            })

            if (arrayDoctorId.length > 0) {
                let exceptionalOpen = {
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    arrayDoctorId: arrayDoctorId
                }
                this.props.getsExceptionalOpenByDoctors(exceptionalOpen);
                this.props.getsExceptionalCloseByDoctors(exceptionalOpen);
            }


            const arrayAgreements = (this.props.agreements && this.props.agreements.length) ? this.formatAgreements(this.props.agreements) : [];

            const availabilitys = (this.props.availabilitys && this.props.availabilitys.length) ? this.formatAvailabilitys(this.props.availabilitys, this.props.agreements) : [];

            this.setState({
                arrayAgreements: arrayAgreements,
                availabilitys: availabilitys,
            })

            this.officeHours(this.state.currentDate);
            let usersArray = this.loadDoc(this.props.agreements);

            if(this.props.user) {
                usersArray.push(this.props.user._id);                
            }
            let data = {
                usersArray: usersArray,
                startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
                endDate: moment(this.state.endDate).format('YYYY-MM-DD')
            }

            if (this.state.modalNewAppointment === false) {
                this.props.getAppointmentsByUser(data);
            }
        }

        if (prevProps.appointmentsByUsers !== this.props.appointmentsByUsers) {
            let userId = this.state.userSelect ? this.state.userSelect.id : this.props.user._id;
            const appointmentsEvents = (this.props.appointmentsByUsers && this.props.appointmentsByUsers.length) ? AppointmentUtil.formatAppointments(this.props.appointmentsByUsers, userId) : [];

            this.setState({
                appointments: appointmentsEvents
            })
        }
    }

    onNavigate(date) {
        this.props.allBySubWithDate(moment(date).startOf('week').format('YYYY-MM-DD'), moment(date).endOf('week').subtract(1, 'day').format('YYYY-MM-DD'));

        this.setState({
            currentDate: date,
            startDate: moment(date).startOf('week'),
            endDate: moment(date).endOf('week').subtract(1, 'day'),
        })
    }

    openNewAppointment(event, validation) { 
        let dateOfTheDay = moment();
        // Bloquer l'ouverture de rdv dans le passé
        if (moment(event.start).isBefore(dateOfTheDay, 'day')) return;

        let openNewAppointment = false

        if (this.props.agreements && this.props.agreements.length > 0) {
            for (let agreement of this.props.agreements) {
                if (dateOfTheDay.isBetween(agreement.replacement.startDate, agreement.replacement.endDate, 'day', [])) {
                    openNewAppointment = true;
                    break;
                }
            }
        }

        if (openNewAppointment === true) {
            if (this.state.showViewExceptionalOpen === true) {
                this.setState({
                    modalExceptionalOpen: true,
                    dateNewAppointmentSelected: event
                })
            } else if (this.state.showViewExceptionalClose === true) {
                let start_hour = '';
                let end_hour = '';

                let overlap = AppointmentUtil.checkOverlapWithAppointment(event.start, event.end, start_hour, end_hour, this.props.appointmentsByUsers, this.state.userSelect.id)

                if (overlap && !validation) {
                    this.setState({
                        modalWarningExceptionalClose: true,
                        warning: overlap,
                        dateNewAppointmentSelected: event
                    })
                    return;
                }

                this.setState({
                    modalExceptionalClose: true,
                    dateNewAppointmentSelected: event
                })
            } else {
                this.setState({
                    modalNewAppointment: true,
                    dateNewAppointmentSelected: event
                })
            }
        }
    }

    loadDoc(agreements) {
        let arrayDoc = [];
        let arrayIdDoc = [];

        if (agreements && agreements.length > 0) {
            agreements.forEach((agreement) => {
                let found = arrayDoc.find((elem) => {
                    return elem.doctor_id === agreement.doctor._id
                })
                if (!found) {
                    arrayDoc.push({
                        name: agreement.doctor.name,
                        first_name: agreement.doctor.first_name,
                        doctor_id: agreement.doctor._id,
                        role: agreement.doctor.role,
                        image: agreement.doctor.image
                    })
                    arrayIdDoc.push(agreement.doctor._id);
                }
            })
        }

        let found = arrayDoc.find((elem) => {
            return elem.doctor_id === this.state.userSelect.id
        })

        if (!found) {
            let subSelected = this.state.arrayDoc.find((elem) => {
                return elem.doctor_id === this.state.userSelect.id;
            })

            if (subSelected) arrayDoc.push(subSelected);            
        }

        this.setState({
            arrayDoc
        })
        return arrayIdDoc;
    }

    loadAppointmentsByUser() {
        if(!this.props.user) return
        let usersArray = this.loadDoc(this.props.agreements);

        let data = {
            startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
            endDate: moment(this.state.endDate).format('YYYY-MM-DD')
        }

        let arrayReplacementId = [];
        
        if (this.props.agreements && this.props.agreements.length > 0) {
            this.props.agreements.forEach((agreement) => {
                arrayReplacementId.push(agreement.replacement._id);
            })
        }

        data.arrayReplacementId = arrayReplacementId
        usersArray.push(this.props.user._id);
        data.usersArray = usersArray;

        this.props.getAppointmentsByUser(data);

        this.props.allBySubWithDate(moment(this.state.startDate).format('YYYY-MM-DD'), moment(this.state.endDate).format('YYYY-MM-DD'));

    }

    closeModalNewAppointment(date) {
        if (!moment(date).isBetween(this.state.startDate, this.state.endDate, undefined, '[]')) {
            this.loadAppointmentsByUser();
        }

        this.setState({
            modalNewAppointment: false,
            dateNewAppointmentSelected: ''
        })
    }

    openModalTakeAppointment() {
        if (this.state.showViewAppointment === false) {
            this.setState({
                modalTakeAppointment: true
            })
        } else {
            let concatEvents = this.state.arrayAgreements.concat(this.state.appointments);

            this.setState({
                showViewAppointment: false,
                events: concatEvents
            })
        }
    }

    closeModalTakeAppointment() {
        this.setState({
            modalTakeAppointment: false
        })
    }

    closeModalWarning() {
        this.setState({
            modalWarning: false
        })
    }

    closeModalExceptionalOpen() {
        this.setState({
            modalExceptionalOpen: false,
            modalExceptionalClose: false,
            showViewExceptionalOpen: false,
            showViewExceptionalClose: false,
            modalTakeAppointment: false,
            events: []
        })
    }

    closeModalWarningExceptionalClose() {
        this.setState({
            modalWarningExceptionalClose: false
        })
    }

    showViewAppointment() {
        const emptyAppointments = AppointmentUtil.emptyAppointments(this.props.appointmentsByUsers, this.state.arrayOfficeHours, this.props.exceptionalOpen, this.props.exceptionalClose, this.state.userSelect.id);
        let concatEvents = this.state.events.concat(emptyAppointments);
        
        this.setState({
            showViewAppointment: true,
            modalTakeAppointment: false,
            events: concatEvents
        })
    }

    showViewExceptionalOpen() {
        const exceptionalOpens = (this.props.exceptionalOpen && this.props.exceptionalOpen.length) ? AppointmentUtil.exceptionalOpen(this.props.exceptionalOpen) : [];
        let concatEvents = this.state.events.concat(exceptionalOpens);

        this.setState({
            showViewExceptionalOpen: true,
            modalTakeAppointment: false,
            events: concatEvents
        })
    }

    showViewExceptionalClose() {
        const exceptionalCloses = (this.props.exceptionalClose && this.props.exceptionalClose.length) ? AppointmentUtil.exceptionalClose(this.props.exceptionalClose) : [];
        let concatEvents = this.state.events.concat(exceptionalCloses);

        this.setState({
            showViewExceptionalClose: true,
            modalTakeAppointment: false,
            events: concatEvents
        })
    }

    editModal(event) {
        if (event.css === 'exceptionalOpen') {
            this.setState({
                modalExceptionalOpen: true,
                dateNewAppointmentSelected: event
            })
        } else if (event.css === 'exceptionalClose') {
            this.setState({
                modalExceptionalClose: true,
                dateNewAppointmentSelected: event
            })
        } else if (event.css === 'appointment') {
            this.props.removeAppointmentModal(event.original._id);
            this.setState({
                modalEditAppointment: true,
                appointment: event.original
            })
        } else if (event.css === 'emptyAppointment') {
            this.openNewAppointment(event);
        }
    }

    closeModalEditAppointment(date) {
        if (!moment(date).isBetween(this.state.startDate, this.state.endDate, undefined, '[]')) {
            this.loadAppointmentsByUser();
        }
        
        this.setState({
            modalEditAppointment: false,
        })
    }

    officeHours(date) {
        if(!this.props.user) return
        let arrayOfficeHours = AppointmentUtil.officeHours(date, this.props.user.role, undefined, this.props.agreements);

        this.setState({
            arrayOfficeHours: arrayOfficeHours
        })
    }

    dayEventCustom(event) {
        let css = 'pt-1 pl-1';
        let content = moment(event.event.start).format('HH:mm') + ' ' + event.title;

        if(event.event.css === 'replacement') {
            content = event.event.title;
            css = 'pt-1';
        } else if (event.event.css === 'emptyAppointment') {
            content = moment(event.event.start).format('HH:mm') + ' ' + event.event.title;
        } else if (event.event.css === 'availability') {
            content = event.event.title;
        }

        return (
            <div className={'text-color-black ' + css}>
                {content}
            </div>
        )
    }

    collapseHours() {
        if (this.state.collapseHours === true) {
            this.setState({
                min: new Date(0, 0, 0, 0, 0, 0),
                max: new Date(0, 0, 0, 23, 59, 59),
                collapseHours: false
            })
        } else {
            this.setState({
                min: new Date(0, 0, 0, 7, 0, 0),
                max: new Date(0, 0, 0, 20, 0, 0),
                collapseHours: true
            })
        }
    }

    formatAgreements(agreements) {
        var arrayAgreements = [];
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        
        for (let agreement of agreements) {
            let nextRound = false;
            if (moment(agreement.replacement.end_date).isAfter(startDate) && moment(agreement.replacement.start_date).isBefore(endDate)) {
                nextRound = true;
            }
            if (nextRound === false) {
                continue;
            }
            let clonedReplacement = Util.clone(agreement);

            clonedReplacement._type = "Replacement";
            clonedReplacement.start = clonedReplacement.start_date;
            clonedReplacement.end = clonedReplacement.end_date;

            let events = [];

            agreement.replacement.days_available.forEach((elem) => {
                let event = {};
                clonedReplacement.title = (
                    <Col sm="12" className="lp-1">
                        <img src={ProfileImages.getSrc(agreement.doctor.image)}
                            className="d-block mx-auto mb-3" width="50" alt={agreement.doctor.name}>
                        </img>
                        {agreement.doctor.first_name + " " + agreement.doctor.name} <br></br>
                        {moment(elem.start).format('HH:mm')} - {moment(elem.end).format('HH:mm')}
                    </Col>
                );

                event._type = "Replacement";
                event.allDay = true
                event.title = clonedReplacement.title;
                event.color = clonedReplacement.type;
                event.start = new Date(elem.start);
                event.end = new Date(elem.end);
                event.original = clonedReplacement;
                event.css = 'replacement';

                events.push(event);
            })

            arrayAgreements = arrayAgreements.concat(events);
        }
        return arrayAgreements;
    };

    formatAvailabilitys(availabilitys, replacements) {
        var result = [];
        var arrayDates = [];  

        for (let availability of availabilitys) {
            let arrayResult = [];
            // pour les dispo "regular"
            if (Array.isArray(availability.day)) {
                // On pousse dans le tableau les numéros des jours de la semaine qui nous intéresse
                arrayResult = availability.day.map((elem) => {
                    if (elem === 'sunday') {
                        return 0;
                    } else if (elem === 'monday') {
                        return 1;
                    } else if (elem === 'tuesday') {
                        return 2;
                    } else if (elem === 'wednesday') {
                        return 3;
                    } else if (elem === 'thursday') {
                        return 4;
                    } else if (elem === 'friday') {
                        return 5;
                    } else if (elem === 'saturday') {
                        return 6;
                    } else {
                        return 0;
                    }
                })
            }

            // Rajoute au tableau les jours entre deux dates
            availability.days_available.forEach((day) => {
                let addDate = true;
                if (replacements.length > 0) {
                    let substitute_id = this.props.substituteCharacteristic.substitute_id;
                    replacements.forEach((replacement) => {
                        let nego = {};
                        if (replacement.replacement.applicants.length > 0) {
                            replacement.replacement.applicants.forEach((elem) => {
                                if (elem.substitute_id === substitute_id && elem.negociation && elem.negociation.length > 0) {
                                    nego = elem.negociation[elem.negociation.length - 1];
                                    nego.guards = nego.guards.filter((elem) => {
                                        return elem.accepted === true;
                                    })
                                } 
                            })
                        }
                        replacement = Object.assign({}, replacement, nego);

                        replacement.replacement.days_available.forEach((elem) => {
                            // RAJOUTER GESTION JOUR REGULAR
                            if (moment(elem.start).isSame((day.start), 'day')) {
                                addDate = false;
                                return;
                            }
                        })
                    })
                }

                // Rechercher si la date a déjà été ajouté au tableau
                let found = arrayDates.find((elem) => {
                    return elem.date === moment(day.start).format('YYYY-MM-DD')
                })

                // si la date n'a jamais été rajouté
                if (!found && addDate === true) {
                    let obj = {
                        date: moment(day.start).format('YYYY-MM-DD'),
                        type: [availability.type],
                        data: [{
                            _id: availability._id,
                            type: availability.type,
                            start_date: availability.start_date,
                            end_date: availability.end_date,
                            retrocession_wished: availability.retrocession_wished,
                            radius_preference: availability.radius_preference,
                            views: availability.views,
                            privateReplacementsCount: availability.privateReplacementsCount,
                            day: moment(day.start).day()
                        }]
                    }

                    // Si ce n'est pas une régular ou si c'est une régular mais que c'est bien le bon jour
                    if (availability.type !== "regular" || arrayResult.includes(moment(day.start).day())) {
                        arrayDates.push(obj);
                    }
                } else if (addDate === true) {
                    let data = {
                        _id: availability._id,
                        type: availability.type,
                        start_date: availability.start_date,
                        end_date: availability.end_date,
                        retrocession_wished: availability.retrocession_wished,
                        radius_preference: availability.radius_preference,
                        views: availability.views,
                        privateReplacementsCount: availability.privateReplacementsCount,
                        day: moment(day.start).day()
                    }

                    // Si ce n'est pas une régular ou si c'est une régular mais que c'est bien le bon jour
                    if (availability.type !== "regular" || arrayResult.includes(moment(day.start).day())) {
                        found.type.push(availability.type)
                        found.data.push(data)
                    }
                }
            })
        }

        // Boucle sur le nouveau tableau pour rajouter les différentes couleurs dans une seule case
        for (let elem of arrayDates) {
            let className;
            if (elem.type.length === 1) {
                if (elem.type[0] === 'occasional') {
                    className = <Col sm="12" className="paddingTopCustom border-0 bg-paleblue text-dark lp-1">
                        <div className="green-light ml-0 pl-0"></div>&nbsp;
                        <FormattedMessage id="Availability.Short" />
                    </Col>
                } else if (elem.type[0] === 'regular') {
                    className = <Col sm="12" className="paddingTopCustom border-0 bg-paleblue text-dark lp-1">
                        <div className="blue-light ml-0 pl-0"></div>&nbsp;
                        <FormattedMessage id="Availability.Short" />
                    </Col>
                } else if (elem.type[0] === 'guard') {
                    className = <Col sm="12" className="paddingTopCustom border-0 bg-paleblue text-dark lp-1">
                        <div className="red-light ml-0 pl-0"></div>&nbsp;
                        <FormattedMessage id="Availability.Short" />
                    </Col>
                }
            } else if (elem.type.length === 2) {
                if (elem.type.includes('occasional') && elem.type.includes('regular')) {
                    className = <Col sm="12" className="paddingTopCustom border-0 bg-paleblue text-dark lp-1">
                        <div className="green-light ml-0 pl-0"></div>
                        <div className="blue-light ml-0 pl-0"></div>&nbsp;
                        <FormattedMessage id="Availability.Short" />
                    </Col>
                } else if (elem.type.includes('occasional') && elem.type.includes('guard')) {
                    className = <Col sm="12" className="paddingTopCustom border-0 bg-paleblue text-dark lp-1">
                        <div className="green-light ml-0 pl-0"></div>
                        <div className="red-light ml-0 pl-0"></div>&nbsp;
                        <FormattedMessage id="Availability.Short" />
                    </Col>
                } else if (elem.type.includes('regular') && elem.type.includes('guard')) {
                    className = <Col sm="12" className="paddingTopCustom border-0 bg-paleblue text-dark lp-1">
                        <div className="blue-light ml-0 pl-0"></div>
                        <div className="red-light ml-0 pl-0"></div>&nbsp;
                        <FormattedMessage id="Availability.Short" />
                    </Col>
                }
            } else if (elem.type.length === 3) {
                className = <Col sm="12" className="paddingTopCustom border-0 bg-paleblue text-dark lp-1">
                    <div className="green-light ml-0 pl-0"></div>
                    <div className="red-light ml-0 pl-0"></div>
                    <div className="blue-light ml-0 pl-0"></div>&nbsp;
                    <FormattedMessage id="Availability.Short" />
                </Col>
            }

            let eventObj = {
                start: moment(elem.date).hours(22).toDate(),
                end: moment(elem.date).hours(21).minutes(59).seconds(59).toDate(),
                allDay: true,
                color: "dark",
                title: className,
                _type: 'Availability',
                data: elem.data,
                css: 'availability'
            }      

            result.push(eventObj)    
        }
        return result;
    }

    handleDragEvent(event) {
        let appointment_date = moment(event.start);

        let check = AppointmentUtil.checkAppointment(event.start, event.event.original.duration, event.event.original._id, this.state.arrayOfficeHours, this.props.appointmentsByUsers, this.props.exceptionalOpen, this.props.exceptionalClose, this.props.user._id);

        // reconstruit la date en rajoutant l'heure
        appointment_date.set({
            hour: moment(event.start, 'HH:mm').hour(),
            minute: moment(event.start, 'HH:mm').minute()
        })

        if (check.appointments === true || check.officeHours === true) {
            this.setState({
                modalWarning: true,
                warning: check.appointments === true ? 'Vous allez déplacer ce patient sur un autre rdv patient. Continuer ?' : 'Vous allez déplacer ce patient en dehors des horaires d\'ouverture du cabinet. Continuer ?',
                event: event
            })
            return;
        }

        let appointment = {
            _id: event.event.original._id,
            patient_id: event.event.original.patient._id,
            doctor_id: event.event.original.doctor_id,
            appointment_date: appointment_date,
            name: event.event.original.patient.name,
            first_name: event.event.original.patient.first_name,
            birth_name: event.event.original.patient.birth_name,
            birth_date: event.event.original.patient.birth_date,
            mobile_phone: event.event.original.patient.mobile_phone,
            fixed_phone: event.event.original.patient.fixed_phone,
            email: event.event.original.patient.email,
            comments: event.event.original.comments,
            duration: event.event.original.duration,
            user_id: event.event.original.user_id,
            gender: event.event.original.patient.gender,
        };

        if (this.props.arrayAgreements && this.props.arrayAgreements.length > 0) {
            this.props.arrayAgreements.forEach((agreement) => {
                if (moment(agreement.start).isSame(this.state.appointment_date, 'day')) {
                    appointment.replacement_id = agreement._id;
                }
            })
        }

        let appointmentsUpdate = Array.from(this.state.appointments);
        for (let appointment of appointmentsUpdate) {
            if (appointment.original._id === event.event.original._id) {
                appointment.start = event.start;
                appointment.end = event.end;
                break;
            }
        }
        
        this.setState({
            appointments: appointmentsUpdate
        })

        this.props.editAppointment(appointment, event.event.original.user_id, () => {

            new Noty({
                type: "info",
                layout: 'topRight',
                theme: 'bootstrap-v4',
                text: this.props.intl.formatMessage({ id: 'Appointment.Edit' }),
                timeout: 6000,
            }).show();
        });
    }

    customToolBar(toolbar) {

        const goToToday = () => {
            this.props.allBySubWithDate(moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').subtract(1, 'day').format('YYYY-MM-DD'));

            this.setState({
                currentDate: new Date(),
                startDate: moment().startOf('week'),
                endDate: moment().endOf('week').subtract(1, 'day'),
            })
        }

        const goToBack = () => {

            let mDate = toolbar.date;
            let newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() - 7,
                1
            );

            toolbar.onNavigate("prev", newDate);
        }

        const goToNext = () => {

            let mDate = toolbar.date;
            let newDate = new Date(
                    mDate.getFullYear(),
                    mDate.getMonth(),
                    mDate.getDate() + 7,
                    1
                );

            toolbar.onNavigate("next", newDate);
        };

        const onChangeShowFilter = () => {
            this.setState({
                showFilter: !this.state.showFilter
            })
        }

        const onChangeFilter = (event) => {
            let id = event.currentTarget.getAttribute('data-user-id');

            if (id === this.state.userSelect.id) {
                return;
            }

            let user = this.state.arrayDoc.find((sub) => {
                return sub.doctor_id === id;
            });

            const appointmentsEvents = (this.props.appointmentsByUsers && this.props.appointmentsByUsers.length) ? AppointmentUtil.formatAppointments(this.props.appointmentsByUsers, id) : [];

            this.setState({
                userSelect: {
                    id: id,
                    name: user ? user.name : this.props.user.name,
                    first_name: user ? user.first_name : this.props.user.first_name,
                    image: user ? user.image : this.props.user.image
                },
                appointments: appointmentsEvents,
            })
        }

        const goToWeekView = () => {
            return;
        }

        const goToMonthView = () => {
            this.props.changeView('month');
        };

        const goToYearView = () => {
            this.props.changeView('year');
        };

        var dateEntete = toolbar.label.toUpperCase();

        return (
            <div className='row pt-5 pb-5'>
                <div className='col-md-12 col-sm-12 d-flex justify-content-center'>
                    <div className='col-3 d-flex justify-content-around '>
                        <span className="clickable "onClick={goToYearView}>
                                <div className="buttonMonth">A</div>
                        </span>
                        <span className="clickable" onClick={goToMonthView}>
                            <div className="buttonMonth">M</div>
                        </span>
                        <span className="clickable surround-view-calendar" onClick={goToWeekView}>
                            <div className="buttonMonth surround-view-calendar-text text-center">S</div>
                        </span>
                    </div>
                </div>
                <div className='col-12 col-md-9 text-center header-date-calendar'>
                    <div className='row d-flex align-items-center'>
                        <div className='col-md-2 col-1'>
                        <Dropdown isOpen={this.state.showFilter} toggle={onChangeShowFilter}>
                            <DropdownToggle caret>
                                Filtrer
                            </DropdownToggle>
                            <DropdownMenu className='dropDownMenuFilter dropdown-item-border-radius'>
                                <div className='dropdown-item-custom'>
                                    <div name="showUser" id="showUser" data-user-id={this.props.user._id } onClick={onChangeFilter} className={"text-color-black clickable " + (this.state.userSelect.id === this.props.user._id ? 'bold' : '')}>
                                        {this.props.user.name}&nbsp;{this.props.user.first_name}
                                    </div>
                                </div>
                                {this.state.arrayDoc.length > 0 &&
                                    this.state.arrayDoc.map((doc, key) => 
                                        <div key={key} className='dropdown-item-custom'>
                                            <div name="showUser" data-role={doc.role} data-user-id={doc.doctor_id} onClick={onChangeFilter} className={"text-color-black clickable " + (this.state.userSelect.id === doc.doctor_id  ? 'bold' : '')}>
                                                {doc.name}&nbsp;{doc.first_name}
                                            </div>
                                        </div>
                                    )
                                }
                            </DropdownMenu>
                        </Dropdown>
                        </div>
                        <div className='col-md-2 col-1'>
                            <button className="custom-button-today" onClick={goToToday}>
                                <i className="fa fa-calendar-check fa-2x"></i>
                            </button>
                        </div>
                        <div className='col-md-8 col-10 d-flex align-items-center justify-content-center'>
                            <div className='arrow-button-custom' type="button" onClick={goToBack}>
                                <span className="prev-icon-arrow">&#8249;</span>
                            </div>
                            <div className="dateEnteteSCalendar calendar-week-size-date">{dateEntete}</div>
                            <div className='arrow-button-custom' type="button" onClick={goToNext}>
                                <span className="next-icon-arrow">&#8250;</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-2 d-flex justify-content-end align-items-center pr-3">
                    <span onClick={this.openModalTakeAppointment} className="bg-orange text-white text-center clickable circleCustom">
                        {this.state.showViewAppointment === false ? 
                            '+' : '-'
                        }
                    </span>
                </div>
                <div className='col-12 d-flex align-items-center justify-content-center bold pt-3'>
                    <img src={ProfileImages.getSrc(this.state.userSelect.image)}
                        className="" width="50" alt={this.state.userSelect.name}>
                    </img>
                </div>
                <div className='col-12 d-flex align-items-center justify-content-center bold'>
                    {this.state.userSelect.name}                         
                </div>
            </div>
        )
    }
    
    loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-white mx-auto" role="status"></div></div>;

    timeSlotWrapper(props) {
        let addStyle = {}
        let timeIndicator = {}
        // console.log(props)
        return React.cloneElement(
            props.children,
            {
                style: { ...addStyle },
                'data-time': moment(props.value).format('HH:mm'),
                ...timeIndicator,
                onClick: this.collapseHours
            })
    }

    render() {
        const { substituteCharacteristic, user, agreements, exceptionalOpen, exceptionalClose } = this.props;
        if (!substituteCharacteristic || this.state.loading || !user) return this.loading();
        
        let events = this.state.arrayAgreements.concat(this.state.appointments).concat(this.state.events).concat(this.state.calendarEvents).concat(this.state.availabilitys);
        return (
            <React.Fragment>
                <Card className="p-0 rounded-0" id="customSCalendarWeek">
                    <DnDCalendar
                        components={{ 
                            timeSlotWrapper: this.timeSlotWrapper,
                            toolbar: this.customToolBar,
                            week: {
                                event: this.dayEventCustom,
                            },
                        }}
                        renderable
                        selectable
                        formats={{
                            eventTimeRangeFormat: range =>
                            ``
                        }}
                        popup={true}
                        step={5}
                        timeslots={6}
                        min={this.state.min}
                        max={this.state.max}
                        localizer={localizer}
                        defaultDate={this.state.currentDate}
                        date={this.state.currentDate}
                        onView={{}}
                        events={events}
                        view={'week'}
                        slotPropGetter={(date) => AppointmentUtil.customSlotPropGetterSub(date, this.state.arrayOfficeHours, exceptionalOpen, agreements, exceptionalClose)}
                        views={{
                            week: WorkWeek
                        }}
                        culture={'fr'}
                        onNavigate={this.onNavigate}
                        onSelectSlot={this.openNewAppointment}
                        onSelectEvent={this.editModal}
                        eventPropGetter={(event) => AppointmentUtil.titleAccessor(event, this.state.showViewAppointment, this.state.showViewExceptionalOpen, this.state.showViewExceptionalClose)}
                        onEventDrop={this.handleDragEvent}
                        dayLayoutAlgorithm="no-overlap"
                        draggableAccessor={AppointmentUtil.draggableAccessor}
                    />
                </Card>
                
                {this.state.modalNewAppointment === true && 
                    <SModalNewAppointment
                        close={this.closeModalNewAppointment}
                        dateNewAppointmentSelected={this.state.dateNewAppointmentSelected}
                        arrayAgreements={this.state.arrayAgreements}
                        appointmentsEvents={this.state.appointments}
                        startWeekDate={this.state.startDate}
                        agreements={agreements}
                        exceptionalOpen={exceptionalOpen}
                        arrayOfficeHours={this.state.arrayOfficeHours}
                        exceptionalClose={exceptionalClose}
                    />
                }
                
                {this.state.modalTakeAppointment === true &&
                    <SModalTakeAppointment
                        close={this.closeModalTakeAppointment}
                        showViewAppointment={this.showViewAppointment}
                        showViewExceptionalOpen={this.showViewExceptionalOpen}
                        showViewExceptionalClose={this.showViewExceptionalClose}
                    />
                }
                
                {this.state.modalEditAppointment === true && 
                    <SModalEditAppointment
                        close={this.closeModalEditAppointment}
                        appointment={this.state.appointment}
                        agreements={agreements}
                        appointmentsEvents={this.state.appointments}
                        arrayAgreements={this.state.arrayAgreements}
                        arrayOfficeHours={this.state.arrayOfficeHours}
                        exceptionalOpen={exceptionalOpen}
                        exceptionalClose={exceptionalClose}
                        appointmentsByUsers={this.props.appointmentsByUsers}
                    />
                }

                {this.state.modalWarning === true &&
                    <SModalWarningAppointment
                        close={this.closeModalWarning}
                        message={this.state.warning}
                        event={this.state.event}
                    />
                }

                {this.state.modalExceptionalOpen === true &&
                    <SModalExceptionalOpen
                        close={this.closeModalExceptionalOpen}
                        dateNewAppointmentSelected={this.state.dateNewAppointmentSelected}
                        arrayAgreements={this.state.arrayAgreements}
                        arrayOfficeHours={this.state.arrayOfficeHours}
                        exceptionalOpen={exceptionalOpen}
                        exceptionalClose={exceptionalClose}
                    />
                }

                {this.state.modalExceptionalClose === true &&
                    <SModalExceptionalClose
                        close={this.closeModalExceptionalOpen}
                        dateNewAppointmentSelected={this.state.dateNewAppointmentSelected}
                        arrayAgreements={this.state.arrayAgreements}
                        arrayOfficeHours={this.state.arrayOfficeHours}
                        exceptionalOpen={exceptionalOpen}
                        exceptionalClose={exceptionalClose}
                    />
                }

                {this.state.modalWarningExceptionalClose === true &&
                    <SModalWarningExceptionalClose
                        close={this.closeModalWarningExceptionalClose}
                        message={this.state.warning}
                        openNewAppointment={this.openNewAppointment}
                        event={this.state.dateNewAppointmentSelected}
                    />
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        substituteCharacteristic: state.global.substituteCharacteristic,
        user: state.global.user,
        agreements: state.agreements.agreements,
        exceptionalOpen: state.exceptionalOpen.exceptionalOpen,
        exceptionalClose: state.exceptionalClose.exceptionalClose,
        appointmentsByUsers: state.appointments.appointmentsByUsers
    }
};

const mapDispatchToProps = dispatch => {
    return {
        allBySubWithDate: (startDate, endDate) => dispatch(allBySubWithDate(startDate, endDate)),
        getAppointmentsByUser: (data) => dispatch(getAppointmentsByUser(data)),
		removeAppointmentModal: (appointment) => dispatch(removeAppointmentModal(appointment)),
		editAppointment: (appointment, userId, cbk) => dispatch(editAppointment(appointment, userId, cbk)),
		getsExceptionalOpenByDoctors: (appointment, cbk) => dispatch(getsExceptionalOpenByDoctors(appointment, cbk)),
		getsExceptionalCloseByDoctors: (appointment, cbk) => dispatch(getsExceptionalCloseByDoctors(appointment, cbk)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SCalendarWeeks));