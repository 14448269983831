import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Col, Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { editAppointment } from '../../../actions/appointment/appointment';
import moment from 'moment';
import Noty from 'noty';
import "../../../../node_modules/noty/lib/noty.css";
import "../../../../node_modules/noty/lib/themes/bootstrap-v4.css";

class SModalWarningAppointment extends React.Component {

    constructor(props) {
        super(props);

        moment.locale('fr')

        this.state = {

        };

        this.onSubmit = this.onSubmit.bind(this);
    };

    onSubmit() {
        let event = this.props.event;
        let appointment_date = moment(event.start);

        // reconstruit la date en rajoutant l'heure
        appointment_date.set({
            hour: moment(event.start, 'HH:mm').hour(),
            minute: moment(event.start, 'HH:mm').minute()
        })

        let appointment = {
            _id: event.event.original._id,
            patient_id: event.event.original.patient._id,
            doctor_id: event.event.original.doctor_id,
            appointment_date: appointment_date,
            name: event.event.original.patient.name,
            first_name: event.event.original.patient.first_name,
            birth_name: event.event.original.patient.birth_name,
            birth_date: event.event.original.patient.birth_date,
            mobile_phone: event.event.original.patient.mobile_phone,
            fixed_phone: event.event.original.patient.fixed_phone,
            email: event.event.original.patient.email,
            comments: event.event.original.comments,
            duration: event.event.original.duration,
            user_id: event.event.original.user_id,
            gender: event.event.original.patient.gender,
            replacement_id: event.event.original.replacement_id
        };

        this.props.editAppointment(appointment, this.props.user._id, () => {
            this.props.close();

            new Noty({
                type: "info",
                layout: 'topRight',
                theme: 'bootstrap-v4',
                text: this.props.intl.formatMessage({ id: 'Appointment.Edit' }),
                timeout: 6000,
            }).show();
        });
    }

    render() {

        return (
            <Modal size="lg" isOpen={true} toggle={this.props.close} className="simple-modal simple-modal-white srounded modalCustom">

                <ModalHeader className="header-modal-choice">
                    <div className="text-left">
                        <button type="button" onClick={this.props.close} className="close d-inline-block" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div>
                        Confirmation
                    </div>
                </ModalHeader>

                <ModalBody className="row">
                    <Col sm="12">
                        {this.props.message}
                    </Col>

                </ModalBody>

                <ModalFooter className="justify-content-center">
                    <Button color="secondary" onClick={this.props.close}>Annuler</Button>
                    <Button color="success" onClick={this.onSubmit}>Valider</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.global.user,
    }
};

const mapDispatchToProps = dispatch => {
    return {
		editAppointment: (appointment, userId, cbk) => dispatch(editAppointment(appointment, userId, cbk)),
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SModalWarningAppointment));